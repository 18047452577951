<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >

      <div style="padding: 10px 15px; width: 100%">
        <b-button block variant="success" v-b-modal.modal-suggest>
          <feather-icon
              icon="PlusIcon"
              class="mr-50"
          />
          <span class="align-middle">Добавить</span>
        </b-button>
      </div>

      <!-- modal suggest-->
      <b-modal
          id="modal-suggest"
          ref="modal-suggest"
          cancel-variant="outline-secondary"
          ok-title="Добавить релиз"
          cancel-title="Отмена"
          centered
          title="Добавить релиз"
          hide-footer
      >
        <b-form
            ref="suggest-form"
        >
          <b-form-group
              :state="urlState"
              label="URL:"
              label-for="url-input"
              invalid-feedback="URL обязательно должен быть"
          >
            <b-form-input
                id="url-input"
                :state="urlState"
                v-model="suggestedUrl"
                placeholder="Spotify / Yandex / Youtube url"
                autocomplete="off"
                required
            />
          </b-form-group>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-2"
              variant="outline-primary"
              block
              @click="suggestUrl(suggestedUrl)"
              :disabled="suggestIsLoading"
          >
            <b-spinner
                small
                type="grow"
                v-if="suggestIsLoading"
            />
            Добавить релиз
          </b-button>
        </b-form>
      </b-modal>


      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, BButton, BModal, BForm, BFormGroup, BFormInput, BSpinner } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  setup(props, { refs }) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const toast = useToast()

    const suggestIsLoading = ref(false)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    const suggestedUrl = ref('')
    const urlState = ref()

    const checkFormValidity = () => {
      const valid = refs['suggest-form'].checkValidity()
      urlState.value = valid
      return valid
    }

    const resetModal = () => {
      suggestedUrl.value = ''
      urlState.value = null
    }

    const suggestUrl = (url) => {
      if (!checkFormValidity()) {
        return
      }
      suggestIsLoading.value = true
      store.dispatch('utils/suggest', url)
          .then(response => {
            let success = response.data.success
            let message = response.data.message

            if (success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'PlusIcon',
                  variant: 'success',
                },
              })

              refs['modal-suggest'].hide()
            } else {
              toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'PlusIcon',
                  variant: 'warning',
                },
              })
            }
          })
          .catch(response => {
            console.error(response)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка при добавлении релиза',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            suggestIsLoading.value = false
            resetModal()
          })
    }

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      suggestUrl,
      suggestedUrl,
      urlState,
      suggestIsLoading,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
