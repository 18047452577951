export default [
  {
    header: 'Голосования',
    action: 'vote',
    resource: 'video',
  },
  {
    title: 'Альбомы RU',
    route: 'vote-albums-ru',
    icon: 'DiscIcon',
    action: 'vote',
    resource: 'album',
  },
  {
    title: 'Альбомы EN',
    route: 'vote-albums-en',
    icon: 'DiscIcon',
    action: 'vote',
    resource: 'album',
  },
  {
    title: 'Песни RU',
    route: 'vote-songs-ru',
    icon: 'MusicIcon',
    action: 'vote',
    resource: 'song',
  },
  {
    title: 'Песни EN',
    route: 'vote-songs-en',
    icon: 'MusicIcon',
    action: 'vote',
    resource: 'song',
  },
  {
    title: 'Клипы RU',
    route: 'vote-videos-ru',
    icon: 'YoutubeIcon',
    action: 'vote',
    resource: 'video',
  },
  {
    title: 'Клипы EN',
    route: 'vote-videos-en',
    icon: 'YoutubeIcon',
    action: 'vote',
    resource: 'video',
  },
  {
    title: 'Итоги года RU',
    route: 'year-baskets-ru',
    icon: 'AwardIcon',
    action: 'vote',
    resource: 'basket',
  },
  {
    title: 'Итоги года EN',
    route: 'year-baskets-en',
    icon: 'AwardIcon',
    action: 'vote',
    resource: 'basket',
  },
]
