export default [
  {
    header: 'Результаты',
    action: 'results',
    resource: 'video',
  },
  {
    title: 'Прогресс жюри',
    route: 'users-progress',
    icon: 'UserIcon',
    action: 'progress',
    resource: 'user',
  },
  {
    title: '-Альбомы RU',
    route: 'results-albums-ru',
    icon: 'DiscIcon',
    action: 'results',
    resource: 'album',
  },
  {
    title: '-Альбомы EN',
    route: 'results-albums-en',
    icon: 'DiscIcon',
    action: 'results',
    resource: 'album',
  },
  {
    title: '-Песни RU',
    route: 'results-songs-ru',
    icon: 'MusicIcon',
    action: 'results',
    resource: 'song',
  },
  {
    title: '-Песни EN',
    route: 'results-songs-en',
    icon: 'MusicIcon',
    action: 'results',
    resource: 'song',
  },
  {
    title: '-Клипы RU',
    route: 'results-videos-ru',
    icon: 'YoutubeIcon',
    action: 'results',
    resource: 'video',
  },
  {
    title: '-Клипы EN',
    route: 'results-videos-en',
    icon: 'YoutubeIcon',
    action: 'results',
    resource: 'video',
  },
]
